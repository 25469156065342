import React from 'react'
import biopic from '../assets/images/bio.jpg';

function About() {
  return (
    <div className="Bio">
    <div className="bio-content">
      <img src={biopic} alt="bio" />
      <p>
        Naomi Accardi is an editorial consultant & writer with 10 years
        experience in visual communications and brand marketing. Aside from
        contributing to multiple international publications, her work focuses
        on helping brands and platforms develop and implement a strong
        editorial strategy, find the right tone of voice and offer outstanding
        storytelling and experiences to their audience. 
        <br /> <br />With a special focus
        on the social and cultural connotation of sport (football ⚽️ in
        particular) and its intersection with fashion, Naomi seeks to create
        purposeful content engineered to unearth and elevate inspiring stories
        and put remarkable individuals under the spotlight. <br /> <br />Currently she’s the
        Editor-At-Large of Season Zine. <br/> <br/>

        Full CV <a href='https://drive.google.com/drive/folders/1uEBeUF5ZJ8lgVWQlYtLSm8wrHXOZ5J9a' alt='cv' target='_blank' rel='noreferrer' >here.</a>
        <br /><br />
        <span>Services:</span> Editorial Consulting, Creative Writing, Copywriting, Experience Curation, Creative Production<br/> <br />
        <span>Select Clients:</span> adidas, Mr Porter, Rivista 11, Dazed, C41 Magazine, Day Off Magazine, Greatest, Mundial Magazine, Tomorrow Projects, Camper, Lack of Guidance, I-D, Palla Mag, AC Momento. 
        <br /><br />
        Subscribe to my newsletter <a href='https://naomiaccardi.substack.com/'>Things I Thought About.</a> <br/><br/>
        <a  href='https://instagram.com/naomiaccardi' target='_blank' rel='noreferrer' >Ig</a> / <a  href='https://twitter.com/naomi_accardi' target='_blank' rel='noreferrer'>Twitter </a>/ <a  href='https://www.linkedin.com/in/naomi-accardi/' target='_blank' rel='noreferrer'>Linkedin</a>
        </p>
    </div>
  </div>
  )
}

export default About