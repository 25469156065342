import React from 'react'
import { Link } from 'react-router-dom'

function Nav() {
  return (
         <nav>
          <ul>
            <li className='menu-links' id='menu-link-home'><Link to='/'>Home</Link></li>
            <li className='menu-links' id='menu-link-about'><Link to='/about'>About</Link></li>
            <li className='menu-links' id='menu-link-work'><Link to='/work'>Work</Link></li>
            <li className='menu-links' id='menu-link-contact'><a href='mailto:accardinaomi@gmail.com'>Contact</a></li>
          </ul>
        </nav>
  )
}

export default Nav