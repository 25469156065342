import React from 'react'
import sunneiImages from '../assets/images/sunneiImages'

function Sunnei() {
  return (
    <div className='sunnei'>
    <p><strong>Sunnei Lapietra Inflatable Retail Installation</strong> <br/> <strong>Design:</strong> Parasite 2.0 / <strong>Commissioner:</strong> Sunnei </p>
        {sunneiImages.map(({image, id}) => (
            <img key={id} src={image}  alt='baloon' />
        ))}
    </div>
  )
}

export default Sunnei