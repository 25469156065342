import sunnei1 from './sunnei1.jpg'
import sunnei2 from './sunnei2.jpg'
import sunnei3 from './sunnei3.jpg'


const sunnei = [
    {
     image: sunnei1,
     id: 1
    },
    {
        image: sunnei2,
        id: 2
       },
       {
        image: sunnei3,
        id: 3
       }
]



export default sunnei