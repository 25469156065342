import React from 'react'
import dopoImages from '../assets/images/dopoImages'

function Dopo() {
  return (
    <div className='dopo'>
    <p><strong>Dopo? Press Images</strong><br/> <strong>Photographer: </strong>Mattia Greghi /  <strong>Styling: </strong> Naomi Accardi / <strong>Creative Direction: </strong>Naomi Accardi</p>
        {dopoImages.map(({image, id}) => (
            <img key={id} src={image}  alt='baloon' />
        ))}
    </div>
  )
}

export default Dopo