import React from 'react'




function Home() {

  return (
    <div className='Home'>
        <h1>Creative Consultant 
<br/>& Writer based in Milan / NYC.</h1>
  
       
    </div>
  )
}

export default Home