import dopo1 from './dopo1.jpg'
import dopo2 from './dopo2.jpg'
import dopo3 from './dopo3.jpg'
import dopo4 from './dopo4.jpg'
import dopo5 from './dopo5.jpg'


const dopo = [

    {
        image: dopo1,
        id: 1
    },

    {
        image: dopo2,
        id: 2
    },

    {
        image: dopo3,
        id: 3
    },

    {
        image: dopo4,
        id: 4
    },

    {
        image: dopo5,
        id: 5
    }
]


export default dopo