import React from 'react';


function Work() {
  
  return (
   <div className='Work'>


   <div className='selectedWriting-wrapper'>
   <h1 >Selected Writing</h1>
   <ul>
   <li><h3>Camper / <a href='https://www.camper.com/en_US/content/fall-winter-2021?utm_source=instagram&utm_medium=social&utm_campaign=HQ_ORG_INSTAGRAM_FALLWINTER2021&utm_content=fall-winter-2021' alt='camper' target='_blank' rel='noreferrer' >The Walking Society</a></h3></li>
   <li><h3>Lack of Guidance / <a href='https://drive.google.com/file/d/16jQtsBamKu3rPP9BfbHMJxumq0IO0-_h/view' alt='lack of guidance' target='_blank' rel='noreferrer'>Say No to Racism</a></h3></li>
   <li><h3>Office Mag Issue 17 / <a href='https://drive.google.com/file/d/12-0c3W4KcX-5M2Zy4GgnAPTeTcNsy-gs/view?usp=sharing' alt='office' target='_blank' rel='noreferrer'>Teenage Kicks</a></h3></li>
   <li><h3>C41 / <a href='https://www.c41magazine.com/inside-issue-10-mister-jo-adidas-c41-magazine/' alt='' target='_blank' rel='noreferrer'>Mr Jo, The football coach redefining the value of the game</a></h3></li>
   <li><h3>Dazed / <a href='https://www.dazeddigital.com/life-culture/article/55035/1/inside-nike-shapa-soweto-the-sa-centre-incubating-the-next-champions' alt='' target='_blank' rel='noreferrer'>Inside Nike Shapa Soweto, The SA center incubating the next champions</a></h3></li>
   <li><h3>I-D / <a href='https://i-d.vice.com/en_uk/article/qjb835/nuvany-david-photography-zine' alt='vice' target='_blank' rel='noreferrer'>Nuvany David</a></h3></li>
   <li><h3>Miilkiina / <a href='https://miilkiina.com/contributors/naomi-accardi/' alt='milkiina' target='_blank' rel='noreferrer'>Contributor’s page</a></h3></li>
   <li><h3>Mr Porter / <a href='https://www.mrporter.com/en-be/journal/fashion/football-clubs-new-streetwear-brands-17520630' alt='mrporter' target='_blank' rel='noreferrer'>Are Football Clubs The New Streetwear Brands?</a></h3></li>
   </ul>
   </div>

   <div  className='selectedCreative-wrapper'>
   <h1 >Selected Creative Work</h1>
   <ul>
   <li><h3>Adidas Original / <a href='https://drive.google.com/drive/folders/1VqETGuW9zLR7Y66Lspx3_uYmh2piV7zN' alt='camper' target='_blank' rel='noreferrer' >BYND72</a></h3></li>
   <li><h3>Sunnei / <a href='/sunnei' alt='camper' > Lapietra Inflatable Retail Installation</a></h3></li>
   <li><h3>Dopo? / <a href='/dopo' alt='dopo'>Press Images</a></h3></li>
   <li><h3>Football Research / <a href='https://drive.google.com/drive/folders/1ZxxXYLhcFqsUqlqsCe49_SjVKPU8Ru9c' alt='camper' target='_blank' rel='noreferrer' >Ongoing Project</a></h3></li>
   </ul>
   </div>

   </div>
   
  );
}

export default Work;
